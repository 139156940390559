import React from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import generalClasses from "./general.module.css"

import websiteImage from "../../images/case-studies/revenue-reporting-tool.jpg"
import Footer from "../../components/footer"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"
import RightIcon from "../../images/arrow-right.inline.svg"
import LeftIcon from "../../images/arrow-left.inline.svg"

const RevenueReportingTool = props => (
  <HomePageLayout pageTitle="revenue reporting tool" background>
    <SEO title="Revenue Reporting Tool Case Study" />
    <main className={generalClasses.main}>
      <Link to={"/solutions/projects"} className={generalClasses.backLink}>
        <img src={arrowLeft} alt="" className={generalClasses.backLinkImage} />
        Projects
      </Link>
      <section
        className={[generalClasses.caseStudyIntro, generalClasses.section].join(
          " "
        )}
      >
        <div>
          <h1 className={generalClasses.caseStudyHeader}>
            REVENUE REPORTING TOOL
          </h1>
          <p className={generalClasses.caseStudyDescription}>
            Revenue Reporting Tool is a revenue management solution that
            synchronizes all revenues received from Ministries Departments and
            Agencies of the State, using the entries from all revenue collection
            platforms.
          </p>
          <p className={generalClasses.caseStudyCategory}>
            UI / UX | WEB DEVELOPMENT
          </p>
          {/*<a href="#" className={generalClasses.caseStudyLink}>*/}
          {/*  VISIT WEBSITE*/}
          {/*</a>*/}
        </div>
        <ul className={generalClasses.caseStudyDetails}>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>CLIENT</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Ondo State government
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>LOCATION</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              Ondo, Nigeria
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
            <p className={generalClasses.caseStudyDetailContent}>
              1000 - 1,000,000 Users
            </p>
          </li>
          <li className={generalClasses.caseStudyDetail}>
            <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
            <p className={generalClasses.caseStudyDetailContent}>Government</p>
          </li>
        </ul>
      </section>
      {/*<section className={generalClasses.section}>*/}
      {/*  <h1 className={generalClasses.meetCaseStudyHeader}>*/}
      {/*    Meet the ondo state government*/}
      {/*  </h1>*/}
      {/*  <p className={generalClasses.meetCaseStudyText}>*/}
      {/*    Ondo state is located in the tropical forest of South western part of*/}
      {/*    Nigeria, bounded in the north by Ekiti and Kogi states, in the east by*/}
      {/*    Edo state, in the west by Osun and Ogun states and in the south by the*/}
      {/*    Atlantic ocean. Ondo state is richly blessed with copious natural*/}
      {/*    resources waiting to be tapped. The strategic location of the state*/}
      {/*    coupled with its peaceful and safe environment makes it very*/}
      {/*    attractive to investors.*/}
      {/*  </p>*/}
      {/*</section>*/}
      <section className={generalClasses.section}>
        <div className={generalClasses.contentMargin}>
          <h1 className={generalClasses.sectionHeader}>
            Overview and Challenges
          </h1>
          <p className={generalClasses.sectionText}>
            The analog culture within the public service in Nigeria has hindered
            growth and productivity in the public service. The public service is
            characterized by manual processes, inefficient use of paper and
            decades of rotten files and records. The growth in population has
            also led to an increase in the number of tasks to be carried out
            which becomes increasingly difficult considering the analogue system
            of the public service. The absence of methodology for efficient
            service delivery in the public service has become a challenge
            especially in terms of aggregating revenue across boards, cities,
            and towns.
            <br />
            We designed the revenue reporting tool with core objective of
            improving process efficiency in revenue generation. Monitoring
            streams of revenue generated is a big challenge especially for a
            board that manages revenue generated by State. Ondo State Internal
            Revenue Service (ODIRS) like every other state revenue generation
            board in Nigeria has challenges monitoring revenue flow in the
            state. The tedious and time-consuming processes that lead to
            synchronizing revenue data from all Ministries, Departments, and
            Agencies (MDAs) are enormous and as a result, it is quite difficult
            to aggregate the streams of revenue generated in the state.
          </p>
        </div>
        <div className={generalClasses.contentMargin}>
          <h1 className={generalClasses.sectionHeader}>Solution</h1>
          <p className={generalClasses.sectionText}>
            We built a Revenue Reporting Tool that aggregates revenue entries
            from all MDAs and automates revenue data across different
            revenue-generating sectors in the state. This solution shows at a
            glance how the state is performing in terms of Internally Generated
            Revenue (IGR), gives statistical data of revenue generated from each
            sector and MDA and stores tax records/data of all taxpayers in the
            state. The features on the platform allow setting of revenue targets
            for MDAs, ensures that tax clearance certificates are archived on
            the platform, enables performance evaluation of each MDA,
            synchronization of transaction details and monitoring of tax station
            progress.
          </p>
        </div>
        <div className={generalClasses.contentMargin}>
          <h1 className={generalClasses.sectionHeader}>Result</h1>
          <p className={generalClasses.sectionText}>
            The Revenue Reporting Tool has resulted in 126.83% growth in revenue
            generation per annum and the monthly Internally Generated Revenue
            (IGR) has increased by over 100% when compared with the previous
            year (2017). The platform has eradicated loopholes used by imposters
            to cheat the system which has led to over 98% reduction in
            taxpayer’s data loss. Decision maker can now have a clearer
            perspective on revenue generation, track revenue performance of each
            MDA and MDAs growth can be evaluated through statistical data with
            key indicator shown. The Revenue Reporting Tool has effectively
            reduced administrative costs by almost 50% and improved the employee
            and citizen experience in terms of process automation and tracking
            of tax records or payment certificate.
          </p>
        </div>
      </section>
      <img
        src={websiteImage}
        className={generalClasses.displayImageFull}
        alt=""
        style={{ marginTop: "20px" }}
      />
      <div className={generalClasses.caseStudyPageLinks}>
        <Link
          to="/case-studies/ereporter"
          className={generalClasses.caseStudyPageLink}
        >
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <LeftIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>
              PREVIOUS
            </span>
            <span className={generalClasses.caseStudyPageLinkTitle}>
              eReporter
            </span>
          </p>
        </Link>
        <Link
          to="/case-studies/lms"
          className={[
            generalClasses.caseStudyPageLink,
            generalClasses.caseStudyPageLinkRight,
          ].join(" ")}
        >
          <p className={generalClasses.caseStudyPageLinkText}>
            <span className={generalClasses.caseStudyPageLinkTag}>NEXT</span>
            <span className={generalClasses.caseStudyPageLinkTitle}>LMS</span>
          </p>
          <span className={generalClasses.caseStudyPageLinkIcon}>
            <RightIcon
              className={generalClasses.caseStudyPageLinkIconImage}
              width="11px"
              height="18px"
            />
          </span>
        </Link>
      </div>
    </main>
    <Footer />
  </HomePageLayout>
)

export default RevenueReportingTool
